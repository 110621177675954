import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import store from './store/index'
import './api/mock.js'
import Cookie from 'js-cookie'
Vue.use(ElementUI);
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const token = Cookie.get('token')
  //如果token不存在 说明用户未登录
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  }
  else if (token && to.name === 'login') {//token存在,说明用户登录，此时跳转首页
    next({ name: 'home' })
  }
  else {
    next()
  }
})
new Vue({
  router,
  store,
  created() {
    store.commit('addMenu', router)
  },
  render: h => h(App),
}).$mount('#app')
