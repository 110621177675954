import Mock from 'mockjs'
import homeApi from './mockServeData/home'
import usrApi from './mockServeData/user'
import permission from './mockServeData/permission'
//定义mock请求拦截
Mock.mock('/api/home/getData', homeApi.getStatisticalData)

//用户列表的数据
Mock.mock('/api/user/add', 'post', usrApi.createUser)
Mock.mock('/api/user/edit', 'post', usrApi.updateUser)
Mock.mock('/api/user/del', 'post', usrApi.deleteUser)
Mock.mock(/api\/user\/get/, usrApi.getUserList)

Mock.mock(/api\/permission\/getMenu/, 'post', permission.getMenu)