<template>
    <div>
        <el-menu default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
            :collapse="isCollapse" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" router>
            <h3>{{ isCollapse ? '后台' : '通用后台管理系统' }}</h3>
            <el-menu-item @click="clickMenu(item)" v-for="item in noChildren  " :key="item.name" :index="item.path">
                <i :class="`el-icon-${item.icon}`"></i>
                <span slot="title">{{ item.label }}</span>
            </el-menu-item>
            <el-submenu :index="item.label" v-for="item in hasChildren " :key="item.label">
                <template slot="title">
                    <i :class="`el-icon-${item.icon}`"></i>
                    <span slot="title">{{ item.label }}</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item :index="itemChildren.path" @click="clickMenu(itemChildren)"
                        v-for="itemChildren in item.children" :key="itemChildren.label">{{ itemChildren.label
                        }}</el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 500px;
}

.el-menu {
    height: 100vh;
    border-right: 0px;

    h3 {
        height: 50px;
        color: #fff;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        font-weight: 400;
    }
}
</style>

<script>
import Cookie from 'js-cookie'
export default {
    data() {
        return {
            name: '',
        };
    },
    methods: {
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        clickMenu(item) {
            //当前页面路由与跳转路由不一样时才跳转
            console.log(item);
            this.$store.commit('setRouteName', item.label)
            // if (this.$route.path !== item.path && !(this.$route.path === '/home' && (item.path === '/')))
            //     this.$router.push(item.path)
        },
    },
    computed: {
        //没有子菜单
        noChildren() {
            return this.menuData.filter((item) => {
                return !item.children
            })
        },
        //有子菜单
        hasChildren() {
            return this.menuData.filter((item) => {
                return item.children
            })
        },
        isCollapse() {
            return this.$store.state.tab.isCollape
        },
        menuData() {
            return JSON.parse(Cookie.get('menu')) || this.$store.state.tab.menu
        }

    }
}
</script>