import { render, staticRenderFns } from "./CommonHeader.vue?vue&type=template&id=ec319ecc&scoped=true&"
import script from "./CommonHeader.vue?vue&type=script&lang=js&"
export * from "./CommonHeader.vue?vue&type=script&lang=js&"
import style0 from "./CommonHeader.vue?vue&type=style&index=0&id=ec319ecc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec319ecc",
  null
  
)

export default component.exports