import Cookies from "js-cookie"
export default {
    state: {
        isCollape: false, //控制菜单收起还是展开
        tabsList: [],//面包屑 
        menu: [],
        routerArr: [], //动态路由和静态路由
        routeName: ''
    },
    mutations: {
        //修改菜单收起还是展开的方法
        collapseMenu(state) {
            state.isCollape = !state.isCollape
        },
        // 设置后端传来的menu的数据
        setMenu(state, val) {
            state.menu = val
            Cookies.set('menu', JSON.stringify(val))
        },
        //动态注册路由
        addMenu(state, router) {
            //判断缓存中是否有数据 （容错处理）
            if (!Cookies.get('menu')) return
            const menu = JSON.parse(Cookies.get('menu'))
            state.menu = menu
            //组装动态路由的数据
            const menuArray = []
            menu.forEach(item => {
                if (item.children) {
                    item.children = item.children.map(item => {
                        item.component = () => import(`../views/${item.url}`)
                        return item
                    })
                    menuArray.push(...item.children)
                }
                else {
                    item.component = () => import(`../views/${item.url}`)
                    menuArray.push(item)
                }
            });
            //动态添加路由 
            menuArray.forEach((item) => {
                router.addRoute('Main', item)
            })
        },
        //设置侧边栏信息
        setRouteName(state, val) {
            state.routeName = val
            console.log(val);
        }

    }

}