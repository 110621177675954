<template>
    <div class="header">
        <div class="left">
            <el-button type="primary" icon="el-icon-thumb" @click="handMenu"></el-button>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item> {{ $store.state.tab.routeName === '首页' ?
                    '' : $store.state.tab.routeName }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="right">
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <img src="../assets/beiye.png" alt="user">
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>个人中心</el-dropdown-item>
                    <el-dropdown-item @click.native="enter">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import Cookie from 'js-cookie'
export default {
    methods: {
        handMenu() {
            this.$store.commit('collapseMenu')
        },
        enter() {
            Cookie.remove('token')
            Cookie.remove('menu')
            this.$router.push('/login')
        }
    },
    computed: {
        matched() {
            // console.log(this.$route.matched);
            return this.$route.matched.filter((item) => {
                return item.name
            })
        },
    },

}
</script>

<style lang="less" scoped>
.header {
    width: 100%;
    height: 60px;
    background-color: #333;

    .left {
        width: 500px;
        float: left;
        padding: 10px;

        .el-button {
            float: left;
            margin-right: 10px;
            transform: scale(0.7);
            color: #333;
            background-color: #fff;
        }

        .el-breadcrumb {
            margin: 13px;

            /deep/.is-link {
                color: #fff;
            }
        }
    }

    .right {
        float: right;
        margin: 10px 20px 0 0;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
}
</style>