<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  data() {
    return {

    }
  },

}
</script>

<style>
@import url('../src/assets/reset.css');
</style>
